import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom';
import hero from "../../assets/hero.png";
import bubble1 from "../../assets/bubble1.png"
import bubble2 from "../../assets/bubble2.png"
const BannerOne = () => {
    return (
        <div className="banner banner-style-1">
            <div className="w-[90%] mx-auto"  >
                <div className=" w-full lg:flex" >
                    <div className="col-lg-6">
                        <div className="banner-content " >
                        {/* <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}> */}
    <h1 className="title text-left dm-sans-bold tracking-[1.7px]">Your Network is Your Net Worth</h1>
    <span className="subtitle text-left  lg:mt-0 mt-3 w-full poppins-regular">
    Transform your connections into success with Ether Connect. We're the bridge between Web3 projects and the right audience. Let’s make your success story the next big thing.
    </span>
    {/* <Link
  to="#"
  className=" lg:hidden block axil-btn btn-fill-primary btn-large mt-4 lg:mt-0 w-[60%] lg:w-[30%] mx-auto lg:mx-0 flex justify-center lg:justify-start items-center lg:items-start"
>
  Get Started
</Link> */}
  <Link
  to="#"
  className="axil-btn btn-fill-primary btn-large  lg:mt-0  lg:w-[38%] "
>
  Get Started
</Link>

{/* </AnimationOnScroll> */}



                        </div>
                    </div>
                    <div className=" w-full">
                        <div className='' >
                            {/* <AnimationOnScroll animateIn="zoomIn" duration={2} delay={300} animateOnce={true}> */}
                                {/* <div className="large-thumb">
                                    <img src={process.env.PUBLIC_URL + "/images/banner/window.png"} alt="Laptop" />
                                </div> */}
                            {/* </AnimationOnScroll> */}
                            {/* <AnimationOnScroll animateIn="slideInRight" duration={2} delay={300} animateOnce={true}> */}
                            <div className="large-thumb-2  lg:mt-[3.4rem] ">
                                {/* <img src={bubble1}/>
                                <img src={bubble2}/> */}
                            <img className='mx-auto max-h-[24rem]  w-full '  src={hero} alt="img1" />
                            </div>
                            {/* </AnimationOnScroll> */}
                            <ul className="list-unstyled shape-group">
                                <li className="shape shape-1">
                                    {/* <AnimationOnScroll animateIn="slideInLeft" duration={1} delay={800} animateOnce={true}>
                                        <img src={process.env.PUBLIC_URL + "/images/banner/chat-group.png"} alt="chat" />
                                    </AnimationOnScroll> */}
                                </li> 
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="list-unstyled shape-group-21">
                <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-39.png"} alt="Bubble" />
                </li>
                {/* <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-38.png"} alt="Bubble" />
                </li> */}
                {/* <li className="shape shape-2">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-38.png"} alt="Bubble" />
                </li> */}
                <li className="shape shape-3">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
                <li className="shape shape-4">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
                <li className="shape shape-5">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
                <li className="shape shape-6">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"} alt="Bubble" />
                </li>
                <li className="shape shape-7">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"} alt="Bubble" />
                </li>
            </ul>
        </div>
    )
}

export default BannerOne;