import React from 'react';
import FormOne from '../contact/FormOne';
import connect from "../../assets/Connect.png"

const AboutOne = () => {
    return (
        <section className="section section-padding-equal bg-color-light" id="about">
            <div className="container" >
                <div className="row align-items-center">
                    <div className="col-lg-6">
                    <div className="about-us">
    <div className="section-heading heading-left mb-0 ">
        <span className="subtitle dm-sans-normal">About Us</span>
        <h2 className="title text-[56px] dm-sans-normal">We Connect, Collaborate & Expand</h2>
        <p className=""></p>
        <p className="poppins-regular">
            At <span className='text-[#5956E9] poppins-regular'>Ether Connect</span>, we’re passionate about unlocking the full potential of the Web3 space. Whether you're a crypto native or transitioning from Web2 to Web3, we'll save you countless hours and wasted $$ by avoiding common pitfalls. With a team deeply immersed in blockchain and decentralized technologies, we bring a wealth of knowledge and expertise to every project.
        </p>
    </div>
</div>

                    </div>

                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        {/* <div className="contact-form-box">
                            <h3 className="title">Get a free Sales quote now</h3>
                           <FormOne />
                        </div> */}
                        <img src={connect}/>
                    </div>
                </div>
            </div>
            <ul className="shape-group-6 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"} alt="Bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="line" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" /></li>
            </ul>
        </section>
    )
}

export default AboutOne;