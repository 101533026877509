import React from 'react';
import { AllData } from '../../data/testimonial/TestimonialData';

const TestimonialItem = ({ colSize, itemShow }) => {
    return (
        <div className='row lg:flex hidden'>
            {AllData.slice(0, itemShow).map((data, index) => (
                <div className={`${colSize}`} key={index}>
                    <div className="testimonial-grid">
                        <span className="social-media !min-h-[100px] !min-w-[90px]">
                            <img src={process.env.PUBLIC_URL + data.from} alt={data.fromtext} height={"100px"} width={"90px"} className= " !object-cover !min-h-[100px] !min-w-[90px]"/>
                        </span>
                        <p>{data.description}</p>
                        <div className="author-info">
                            <div className="content mt-4">
                                <span className="name" style={data.fromtext === 'Ether Connect' ? { color: 'blue' } : {}}>
                                    {data.fromtext}
                                </span>
                                <span className="designation">{data.authordesig}</span>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TestimonialItem;
