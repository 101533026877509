import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../../assets/lightlogo.svg"
import logoDark from "../../assets/darklogo.svg"
// import logo from "../../assets/logo.svg"
// import logoDark from "../../assets/darklogo.png"
const Logo = ({limage, dimage, simage}) => {
    return (
        <Link to={process.env.PUBLIC_URL + "/"}>
            <img className="light-version-logo" src={logo} alt="logo" />
            <img className="dark-version-logo" src={logoDark} alt="logo" />
            <img className="sticky-logo" src={logo} alt="logo" />
        </Link>
    )
}


export default Logo;