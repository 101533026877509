import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { Link } from "react-router-dom";
export const MobileFooter=()=>{
    return (
        <>
         <div className="row lg:hidden !flex justify-between">
                <div className="w-[40%]"  >
                  <div className="footer-widget" >
                    <div className="footer-menu-link">
                      <ul className="list-unstyled pt-3" >
                        <ScrollLink
                          to="about"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          <li>
                            <Link to="#">About Us</Link>
                          </li>
                        </ScrollLink>
                        <br />
                        <ScrollLink
                          to="testimonials"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          <li>
                            <Link to="">Testimonials</Link>
                          </li>
                        </ScrollLink>
                        <br />
                        <ScrollLink
                          to="topclients"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          <li>
                            <Link to="">Top Clients</Link>
                          </li>
                        </ScrollLink>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="w-[60%] flex justify-end">
                  <div className="footer-widget">
                    <div className="footer-menu-link">
                      <ul className="list-unstyled pt-3">
                        <ScrollLink
                          to="services"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          <li>
                            <Link to="#">Services</Link>
                          </li>
                        </ScrollLink>
                        <br />
                        <ScrollLink
                          to="whychooseus"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          <li>
                            <Link className="w-full" to="#">
                              Why Choose Us?
                            </Link>
                          </li>
                        </ScrollLink>
                        <br />
                        <ScrollLink
                          to="contact"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          <li>
                            <Link to="#">Contact Us</Link>
                          </li>
                        </ScrollLink>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
        </>
    )
}