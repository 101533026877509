import React from 'react';
import brand1 from "../../assets/brand1.png";
import brand2 from "../../assets/brand2.png";
import brand3 from "../../assets/brand3.png";
import brand4 from "../../assets/brand4.png";
import brand5 from "../../assets/brand5.png";
import brand6 from "../../assets/brand6.png";
// import brand7 from "../../assets/brand7.png";
// import brand8 from "../../assets/brand8.png";

const initialData = [
  { image: brand1 },
  { image: brand2 },
  { image: brand3 },
  { image: brand4 },
  { image: brand5 },
  { image: brand6 },
];

const BrandItem = () => {
  return (
    <div className='image-container-mobile lg:hidden w-[90%] mx-auto' >
      {initialData.map((data, index) => (
        <div className="image-wrappermobile" key={index}>
          <div className="brand-gridmobile">
            <img src={data.image} alt="Brand" style={{ width: "100%", height: "auto" }} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default BrandItem;
