import React from "react";
import { FaEnvelopeOpen } from "react-icons/fa";
import { Link } from "react-router-dom";
import Faq from "../../component/Faq";
import { services } from "../../data/service/ServiceMain";
import { slugify } from "../../utils";
import { GrFacebookOption } from "react-icons/gr";
import { BsTwitterX } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { FaInstagram } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import Logo from "../../elements/logo/Logo";
import { MobileFooter } from "./MobileFooter";
const FooterOne = ({ parentClass }) => {
  return (
    <footer className={`footer-area ${parentClass}`}>
      <div className="container">
        <div className="footer-top">
          <div className="footer-social-link">
            <Faq />
          </div>
        </div>
        <div className="footer-main" id="contact">
          <div className="row">
            <div className="col-xl-6 col-lg-5">
              <div className="footer-widget border-end">
                <div className="footer-newsletter">
                <div className="header-logo">
                                {/* <Logo limage="/images/logo.svg"
                                dimage="/images/logo-3.svg"
                                simage="/images/logo-2.svg"
                                /> */}
                            </div>
                  <h2 className="title text-[56px] font-medium">
                    Get in touch!
                  </h2>
                  {/* <form>
                    <div className="input-group mt-3 lg:border border-[#f1f1f1] flex justify-center items-center">
                      <span className="mail-icon flex justify-center items-center mt-[4px]">
                        <FaEnvelopeOpen />{" "}
                      </span>
                      <input
                        type="email"
                        className="form-control sm:border sm:border-black lg:border-none"
                        placeholder="Email address"
                      />
                      <button
                        className="subscribe-btn mx-auto flex justify-center items-center lg:w-auto w-full"
                        type="submit"
                      >
                        Subscribe
                      </button>
                    </div>
                  </form> */}

                  <div className="flex gap-8 mt-6 ml-4">
                  <a href="mailto:contact@etherconnect.us" className="icon">
                  <IoMdMail  color="gray" size={30}/>
                  </a>
                
                    <a href="https://x.com/EthConnect2024" target="_blank" rel="noopener noreferrer" className="icon">
                      <BsTwitterX color="gray" size={30} />
                    </a>
                    <a href="https://t.me/Etherconnect_us" target="_blank" rel="noopener noreferrer" className="icon">
                      <FaTelegramPlane color="gray" size={30} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="row  hidden">
                <div >
                  <div className="footer-widget">
                    <div className="footer-menu-link">
                      <ul className="list-unstyled pt-3">
                        <ScrollLink
                          to="about"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          <li>
                            <Link to="#">About Us</Link>
                          </li>
                        </ScrollLink>
                        <br />
                        <ScrollLink
                          to="testimonials"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          <li>
                            <Link to="">Testimonials</Link>
                          </li>
                        </ScrollLink>
                        <br />
                        <ScrollLink
                          to="topclients"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          <li>
                            <Link to="">Top Clients</Link>
                          </li>
                        </ScrollLink>
                      </ul>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="footer-widget">
                    <div className="footer-menu-link">
                      <ul className="list-unstyled pt-3">
                        <ScrollLink
                          to="services"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          <li>
                            <Link to="#">Services</Link>
                          </li>
                        </ScrollLink>
                        <br />
                        <ScrollLink
                          to="whychooseus"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          <li>
                            <Link className="w-full" to="#">
                              Why Choose Us?
                            </Link>
                          </li>
                        </ScrollLink>
                        <br />
                        <ScrollLink
                          to="contact"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          <li>
                            <Link to="#">Contact Us</Link>
                          </li>
                        </ScrollLink>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <MobileFooter/>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. All rights reserved by{" "}
                  <a href="https://axilthemes.com/">Ether Connect</a>.
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-bottom-link"></div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
