  
  
  import service1 from "../../assets/service1.png"
import service2 from "../../assets/service2.png"
import service3 from "../../assets/service3.png"
import service4 from "../../assets/service4.png"
import service5 from "../../assets/service5.png"
import service6 from "../../assets/service6.png"
  
  
  export const services=[
    {
        "image": service1,
        "title": "Partnership Development",
        "description": "We’re masters at finding and securing partnerships that vibe perfectly with your platform's goals. Each collaboration is crafted to maximize value and drive mutual growth, ensuring your project thrives.",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image":service2,
        "title": "Client Acquisition",
        "description": "With our extensive network and industry insights, we connect you with high-quality clients tailored to your needs. Our proactive approach guarantees a steady influx of users and clients, propelling your platform to success.",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": service3,
        "title": "User Engagement",
        "description": "Our innovative strategies ensure your users are not just visitors, but active participants. Through targeted campaigns and community-building initiatives, we cultivate a lively, engaged user base.",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": service4,
        "title": "Ambassador Program Management",
        "description": "Turn fans into advocates with our comprehensive ambassador programs. We recruit, train, and support ambassadors who amplify your brand’s reach and impact, driving your project’s visibility.",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image":service5 ,
        "title": "Content strategy",
        "description": "Need top-notch creative content? We’ve got you covered. From custom videos and animations to graphic design and video editing, we craft content strategically tailored for different platforms and project requirements. Your vision, brought to life.",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": service6,
        "title": "Additional Support Services",
        "description": "We’re more than just a marketing agency. From technical assistance to strategic consulting, our tailored support services meet all your platform’s needs, guiding you through the Web3 landscape.",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    

    {
        "image": "/images/icon/icon-29.png",
        "title": "Logo & Branding",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Design",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Logo & Branding design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": "/images/icon/icon-27.png",
        "title": "Website Design",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Design",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": "/images/icon/icon-28.png",
        "title": "Mobile app design",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Design",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": "/images/icon/icon-4.png",
        "title": "Graphic/print design",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Design",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": "/images/icon/icon-30.png",
        "title": "Video production",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Design",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },

    {
        "image": "/images/icon/icon-31.png",
        "title": "Content Managment",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Development",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": "/images/icon/icon-32.png",
        "title": "Mobile app development",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Development",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": "/images/icon/icon-33.png",
        "title": "eCommerce",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Development",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": "/images/icon/icon-34.png",
        "title": "SEO",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Online Marketing",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": "/images/icon/icon-35.png",
        "title": "Pay-per click",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Online Marketing",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": "/images/icon/icon-36.png",
        "title": "Email marketing",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Online Marketing",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": "/images/icon/icon-37.png",
        "title": "Display marketing",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Online Marketing",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": "/images/icon/icon-38.png",
        "title": "Social Media marketing",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Online Marketing",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": "/images/icon/icon-39.png",
        "title": "Digital strategy",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Business",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": "/images/icon/icon-40.png",
        "title": "Business consulting",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Business",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": "/images/icon/icon-41.png",
        "title": "Content writing",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Business",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": "/images/icon/icon-42.png",
        "title": "Reporting",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Business",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": "/images/icon/icon-43.png",
        "title": "Hosting",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Technology",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": "/images/icon/icon-44.png",
        "title": "Big data analysis",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Technology",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": "/images/icon/icon-45.png",
        "title": "Web Audit Practices",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Content strategy",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": "/images/icon/icon-46.png",
        "title": "Marketing Flexibility",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Content strategy",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    },
    {
        "image": "/images/icon/icon-47.png",
        "title": "Advertising Strategy",
        "description": "We design professional looking yet simple Logo. Our designs are search engine and user friendly.",
        "cate": "Content strategy",
        "process": {
            "sectionSub": "Process",
            "sectionTitle": "Our logo design process",
            "para":"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business.",
            "steps": [
                {
                    "thumb": "/images/others/process-1.png",
                    "subtitle": "Step One",
                    "title": "Discover",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-2.png",
                    "subtitle": "Step Two",
                    "title": "Prototype",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "thumb": "/images/others/process-3.png",
                    "subtitle": "Step Three",
                    "title": "Test",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                },
                {
                    "id": 4,
                    "thumb": "/images/others/process-4.png",
                    "subtitle": "Step four",
                    "title": "Build",
                    "paragraph": "Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris."
                }
            ]
        }
    }
    
]
