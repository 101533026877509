import React from 'react';
import { FaLightbulb, FaMoon } from 'react-icons/fa';


const SwitcherHeader = () => {
    

    const switchColor = () => {
        document.querySelector('body').classList.toggle("active-dark-mode");
    }

    return (
        
        <button onClick={switchColor} className='flex items-center justify-center'>
            <span className="setColor dark flex items-center justify-center !w-4 mt-3"><FaLightbulb /></span>
            <span className="setColor light flex items-center justify-center"><FaMoon /></span>
        </button>
       
    )
}

export default SwitcherHeader;