import React from 'react';


const SectionTitle = ({subtitle, title, description, textAlignment, textColor}) => {
    return (
        <div className={`section-heading ${textAlignment} ${textColor}`}>
            <div className="subtitle dm-sans-normal" dangerouslySetInnerHTML={{__html: subtitle}}></div>
            <h2 className="title text-[56px] font-medium dm-sans-normal" dangerouslySetInnerHTML={{__html: title}}></h2>
            <p className="mt-3 poppins-regular" dangerouslySetInnerHTML={{__html: description}}></p>
        </div>
    )
}

export default SectionTitle;