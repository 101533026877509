import React from 'react';
import { Link } from 'react-router-dom';
import newproject from "../../assets/newproject.png";

const CtaLayoutOne = () => {

    return (

        <div className="section call-to-action-area relative" >
            <div className="container">
                <div className="call-to-action">
                    <div className="section-heading heading-light">
                        <span className="subtitle">Let's Work Together</span>
                        <h2 className="title text-[56px] font-normal dm-sans-normal">Turning Ideas into Impacts.</h2>
                       <p className='!-mt-[3rem] !text-white poppins-regular'>Don’t wait to start achieving your marketing goals. Whether you have a specific project in mind or just want to learn more about how we can help, we’re excited to hear from you.</p>
                       <a href="https://t.me/Etherconnect_us" target="_blank" rel="noopener noreferrer" className="axil-btn btn-large btn-fill-white -mt-6 lg:mt-5">
                       Schedule a free consultation
                       </a>
                       
                           
                    
                    </div>
                    <div className="thumbnail absolute bottom-0 " >
                        <div className="-mt-6 lg:-mt-12" data-sal="zoom-in" >
                          <img  className=" mx-auto h-full w-full " alt="img1" src={newproject}/>

                            {/* <img className="paralax-image" src={process.env.PUBLIC_URL + "/images/others/chat-group.png"} alt="Chat" /> */}
                        </div>
                        <ul className="list-unstyled small-thumb">
                            <li className="shape shape-1" data-sal="slide-right" data-sal-duration="800" data-sal-delay="400">
                                {/* <img className="paralax-image" src={process.env.PUBLIC_URL + "/images/others/laptop-poses.png"} alt="Laptop" /> */}
                            </li>
                            <li className="shape shape-2" data-sal="slide-left" data-sal-duration="800" data-sal-delay="300">
                                {/* <img className="paralax-image" src={process.env.PUBLIC_URL + "/images/others/bill-pay.png"} alt="Bill" /> */}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <ul className="list-unstyled shape-group-9">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-12.png"} alt="Comments" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"} alt="Comments" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-13.png"} alt="Comments" /></li>
                <li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Comments" /></li>
                <li className="shape shape-5"><img src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"} alt="Comments" /></li>
                <li className="shape shape-6"><img src={process.env.PUBLIC_URL + "/images/others/bubble-15.png"} alt="Comments" /></li>
                <li className="shape shape-7"><img src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"} alt="Comments" /></li>
            </ul>
        </div>
    )

}

export default CtaLayoutOne;