import React from 'react';
import brand1 from "../../assets/brand1.png";
import brand2 from "../../assets/brand2.png";
import brand3 from "../../assets/brand3.png";
import brand4 from "../../assets/brand4.png";
import brand5 from "../../assets/brand5.png";
import brand6 from "../../assets/brand6.png";
const initialData = [
  { image: brand1 },
  { image: brand2 },
  { image: brand3 },
  { image: brand4 },
  { image: brand5 },
  { image: brand6 },
];

const BrandItem = () => {
  return (
    <div className='image-container lg:block hidden'>
      <div className='marquee'>
        {initialData.map((data, index) => (
          <div className="image-wrapper" key={index}>
            <div className="brand-grid">
              <img src={data.image} alt="Brand" className="brand-image" />
            </div>
          </div>
        ))}
        {/* Duplicate images to create seamless infinite scrolling */}
        {initialData.map((data, index) => (
          <div className="image-wrapper" key={index + initialData.length}>
            <div className="brand-grid">
              <img src={data.image} alt="Brand" className="brand-image" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandItem;
