// import React, { useState, useEffect, useRef } from 'react';
// import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
// import { useSwipeable } from 'react-swipeable';

import React, { useState, useEffect, useRef } from "react";
// import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { useSwipeable } from "react-swipeable";
import container1 from "../../assets/container1.png";
import container2 from "../../assets/container2.png";
import container3 from "../../assets/container3.png";
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
export const data = [
  {
    fromtext: 'Riskman',
    from: container1,
    description: `“Ether Connect is our biggest sales partner. And there is a reason for it they provide the best services and help you really connect you with the blockchain world. We would like to thank Ether Connect for their continuous support on our operations and helping us grow. Truly the best.”`,
    authorimg: '/images/testimonial/testimonial-1.png',
    authorname: 'Riskman',
    authordesig: 'COO, XAlpha',
  },
  {
    fromtext: 'Shouta Aiz',
    from: container2,
    description: `“EtherConnect played a crucial role in the success of our project, XeroAi. Their ability and enthusiastic advocacy boosted our project visibility and user engagement. We couldn't have achieved this level of success without their innovative tactics and unwavering support.”`,
    authorimg: '/images/testimonial/testimonial-2.png',
    authorname: 'Shouta Aiz',
    authordesig: 'COO, Xero AI',
  },
  {
    fromtext: 'Steampunk lela',
    from: container3,
    description: `“Amazing Agency! Helped me grow a lot in the space and get connected to great Projects and Deals.”`,
    authorimg: '/images/testimonial/testimonial-3.png',
    authorname: 'Steampunk lela',
    authordesig: 'CMO, Cryptex',
    style: { color: 'blue' },
  },
];

// function TestimonialSlider() {
//   const [slideIndex, setSlideIndex] = useState(0);
//   const [animating, setAnimating] = useState(false);

//   const previousButton = useRef(null);
//   const nextButton = useRef(null);

//   const previousSlide = useRef(null);
//   const currentSlide = useRef(null);
//   const nextSlide = useRef(null);

//   useEffect(() => {
//     const timeout = setTimeout(() => {
//       nextButton.current.click();
//     }, 5000);

//     return () => {
//       clearTimeout(timeout);
//     };
//   }, [slideIndex]);

//   const getAnimationDuration = () =>
//     getComputedStyle(currentSlide.current.parentElement.parentElement)
//       .getPropertyValue("--slide-duration")
//       .replace(/m?s/g, "");

//   const removeClasses = () => {
//     currentSlide.current.parentElement.childNodes.forEach((e) => {
//       e.classList.remove("left");
//       e.classList.remove("right");
//       e.classList.remove("center");
//     });
//   };

//   const onPreviousButtonClick = () => {
//     if (animating) return;

//     removeClasses();
//     setAnimating(true);

//     currentSlide.current.classList.add("right");
//     previousSlide.current.classList.add("center");

//     setTimeout(() => {
//       setSlideIndex((index) => (index > 0 ? index - 1 : AllData.length - 1));
//       setAnimating(false);
//     }, getAnimationDuration());
//   };

//   const onNextButtonClick = () => {
//     if (animating) return;

//     removeClasses();
//     setAnimating(true);

//     currentSlide.current.classList.add("left");
//     nextSlide.current.classList.add("center");

//     setTimeout(() => {
//       setSlideIndex((index) => (index < AllData.length - 1 ? index + 1 : 0));
//       setAnimating(false);
//     }, getAnimationDuration());
//   };

//   const goToSlide = (slideIndex) => {
//     if (animating) return;

//     removeClasses();
//     setAnimating(true);

//     const newDirection = slideIndex > slideIndex ? 'left' : 'right';
//     setSlideIndex(slideIndex);
//     setTimeout(() => {
//       setAnimating(false);
//     }, getAnimationDuration());
//   };

//   const isPreviousSlide = (i) =>
//     i === (slideIndex > 0 ? slideIndex - 1 : AllData.length - 1);
//   const isNextSlide = (i) =>
//     i === (slideIndex < AllData.length - 1 ? slideIndex + 1 : 0);

//   const handlers = useSwipeable({
//     onSwipedLeft: () => onNextButtonClick(),
//     onSwipedRight: () => onPreviousButtonClick(),
//     preventDefaultTouchmoveEvent: true,
//     trackMouse: true,
//   });

//   return (
//     <div className="slider-container" {...handlers}>
//       <div className="slider">
//         <div className="controls">
//           <div className="control previous" ref={previousButton} onClick={onPreviousButtonClick}>
//             <FiArrowLeft />
//           </div>
//           <div className="control next" ref={nextButton} onClick={onNextButtonClick}>
//             <FiArrowRight />
//           </div>
//         </div>
//         <div className="slides">
//           {AllData.map((slide, index) => (
//             <div
//               key={index}
//               className="slide"
//               data-previous-slide={isPreviousSlide(index)}
//               data-current-slide={index === slideIndex}
//               data-next-slide={isNextSlide(index)}
//               ref={
//                 isPreviousSlide(index)
//                   ? previousSlide
//                   : isNextSlide(index)
//                   ? nextSlide
//                   : index === slideIndex
//                   ? currentSlide
//                   : null
//               }
//             >
//               <img src={slide.from} alt={slide.fromtext} />
//               <div className="text-container">{slide.description}</div>
//             </div>
//           ))}
//         </div>
//         <ul className="slide-pills">
//           {AllData.map((slide, index) => (
//             <li key={index} className={`slide-pill ${index === slideIndex && 'active'}`} onClick={() => goToSlide(index)}></li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// }

// export default TestimonialSlider;





export default function Slider() {
  const [slideIndex, setSlideIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const previousButton = useRef(null);
  const nextButton = useRef(null);

  const previousSlide = useRef(null);
  const currentSlide = useRef(null);
  const nextSlide = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      nextButton.current.click();
    }, 3000); // Change slide every 3 seconds

    return () => clearTimeout(timeout);
  }, [slideIndex]);

  const getAnimationDuration = () =>
    parseInt(
      getComputedStyle(currentSlide.current.parentElement)
        .getPropertyValue("--slide-duration")
        .replace(/m?s/g, "")
    );

  const removeClasses = () => {
    currentSlide.current.parentElement.childNodes.forEach((e) => {
      e.classList.remove("left");
      e.classList.remove("right");
      e.classList.remove("center");
    });
  };

  const onPreviousButtonClick = () => {
    if (animating) return;

    removeClasses();
    setAnimating(true);

    currentSlide.current.classList.add("right");
    previousSlide.current.classList.add("center");

    setTimeout(() => {
      setSlideIndex((index) => (index > 0 ? index - 1 : data.length - 1));
      setAnimating(false);
    }, getAnimationDuration());
  };

  const onNextButtonClick = () => {
    if (animating) return;

    removeClasses();
    setAnimating(true);

    currentSlide.current.classList.add("left");
    nextSlide.current.classList.add("center");

    setTimeout(() => {
      setSlideIndex((index) => (index < data.length - 1 ? index + 1 : 0));
      setAnimating(false);
    }, getAnimationDuration());
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => onNextButtonClick(),
    onSwipedRight: () => onPreviousButtonClick(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const isPreviousSlide = (i) =>
    i === (slideIndex > 0 ? slideIndex - 1 : data.length - 1);
  const isNextSlide = (i) =>
    i === (slideIndex < data.length - 1 ? slideIndex + 1 : 0);

  return (
    <div className="slider-container relative lg:hidden block" {...handlers}>
      <div className="slider relative -mt-[1.6rem]">
        <div className="controls relative">
          <div className="relative">
            <div
              ref={previousButton}
              onClick={() => onPreviousButtonClick()}
              className="control previous absolute top-[17rem] -translate-x-0 translate-y-[-50%] text-2xl rounded-full p-1 bg-black text-white cursor-pointer"
            >
              <FiArrowLeft size={30} />
            </div>
          </div>
          <div
            ref={nextButton}
            onClick={() => onNextButtonClick()}
            className="absolute top-[17rem] -translate-x-0 translate-y-[-50%] right-0 text-2xl rounded-full p-1 bg-black text-white cursor-pointer"
          >
            <FiArrowRight size={30} />
          </div>
        </div>

        <div className="slides !-pt-[2rem]">
          {data.map((e, i) => (
            <div
              className="slide p-[42px] boxshadow"
              data-previous-slide={isPreviousSlide(i)}
              data-current-slide={i === slideIndex}
              data-next-slide={isNextSlide(i)}
              ref={
                isPreviousSlide(i)
                  ? previousSlide
                  : isNextSlide(i)
                  ? nextSlide
                  : i === slideIndex
                  ? currentSlide
                  : null
              }
              key={i}
            >
              <img
                src={e.from}
                alt=""
                className="mb-4 w-32 h-32 object-contain"
              />
              <p className="italic text-left ">{e.description}</p>
              <div className="flex text-left mt-4">
                <div>
                  <h3 className="font-semibold text-left">{e.authorname}</h3>
                  <p className="text-gray-600 text-left">{e.authordesig}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
