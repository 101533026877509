import React from "react";
import { Link } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

const Nav = () => {
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
       
        <li className="menu-item-has-children">
          <ScrollLink
            to="services"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            {" "}
            <Link to="#">Services</Link>
          </ScrollLink>
        </li>
        <li className="menu-item-has-children">
          <ScrollLink
            to="about"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            {" "}
            <Link to="#">About Us</Link>
          </ScrollLink>
        </li>
        <li className="menu-item-has-children">
          <ScrollLink
            to="whychooseus"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <Link to="#">Why Choose Us?</Link>
          </ScrollLink>
        </li>
        <li className="menu-item-has-children">
          <ScrollLink
            to="testimonials"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <Link to="#">Testimonials</Link>
          </ScrollLink>
        </li>
       
        <li className="menu-item-has-children">
        <ScrollLink
            to="topclients"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          > <Link to="#">Top Clients</Link></ScrollLink>
         
        </li>
        <ScrollLink
            to="contact"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
        <button className="navbutton icon" style={{ color: "white" }}>
          {" "}
          Contact Us
        </button>
        </ScrollLink>
      </ul>
    </nav>
  );
};

export default Nav;
