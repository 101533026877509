import container1 from "../../assets/container1.png";
import container2 from "../../assets/container2.png";
import container3 from "../../assets/container3.png";
// import brand3 from "../../assets/brand3.png"
export const AllData = [
  {
    fromtext: "Riskman",
    from: container1,
    description: `“Ether Connect is our biggest sales partner. And there is a reason for it they provide the best services and help you really connect you with the blockchain world. We would like to thank Ether Connect for their continuous support on our operations and helping us grow. Truly the best.”`,
    authorimg: "/images/testimonial/testimonial-1.png",
    authorname: "COO, XAlpha",
    authordesig: "COO, XAlpha",
  },
  {
    fromtext: "Shouta Aiz",
    from: container2,
    description: `“Ether Connect played a crucial role in the success of our project, XeroAi. Their ability and enthusiastic advocacy boosted our project visibility and user engagement. We couldn't have achieved this level of success without their innovative tactics and unwavering support.”`,
    authorimg: "/images/testimonial/testimonial-2.png",
    authorname: "Savannah Nguyen",
    authordesig: "COO, Xero AI",
  },
  {
    fromtext: "Steampunk lela",
    from: container3,
    description: `“Amazing Agency! Helped me grow a lot in the space and get connected to great Projects and Deals.”`,
    authorimg: "/images/testimonial/testimonial-3.png",
    authorname: "Steampunk lela",
    authordesig: "CMO, Cryptex",
    style: { color: "blue" },
  },
];

