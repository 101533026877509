import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import BrandItem from './BrandItem';
import MobileViewBrandItem from './MobileViewBrandItem';


const BrandOne = () => {
    return (
        <div className="section section-padding-2 bg-color-dark" id="topclients" >
        <div className="container" >
            <SectionTitle 
                subtitle="Top Clients"
                title="We’ve built solutions for..."
                description="The most organic solution for social media growth. We help you build a long-term engaged community that fosters trust, leading to exchange listings, increased brand awareness, and overall success."
                textAlignment="heading-light-left"
                textColor=""
            />
            <div >
              <BrandItem/>
               <MobileViewBrandItem/>
            </div>
        </div>
        <ul className="list-unstyled shape-group-10">
            <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-9.png"} alt="Circle" /></li>
        </ul>
    </div>
    )
}

export default BrandOne;