import React, { useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaqData } from "../data/FAQS/FaqData";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="parentfaq lg:mt-10">
      <h2 className="text-center lg:text-left mb-4 lg:text-[56px] text-[32px] font-medium dm-sans-normal">
        Frequently Asked Questions
      </h2>
      {FaqData.map((data, index) => {
        return (
          <div key={index} className="faq-item !w-full">
            <div className="mainFaq px-2">
              <div
                className="faqstyling mt-3 cursor-pointer"
                onClick={() => toggleAccordion(index)}
              >
                <div
                  className="lg:text-lg text-[14px] py-3"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <p  className="lg:text-lg text-[14px]">{data.ques}</p>
                </div>
                <div>
                  <RiArrowDropDownLine size={40} />
                </div>
              </div>
              {activeIndex === index && (
                <div
                  className="faq-content text-lg"
                  style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingBottom: "10px",
                  }}
                >
                  <p className="lg:text-lg text-[14px]">{data.ans}</p>
                  {data.options && (
                    <ul className="lg:text-lg text-[14px] !block list-disc ml-5 mt-4 pl-8">
                      {data.options.map((option, i) => (
                        <>
                        <li key={i}>{option}</li><br/>
                        </>
                      
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Faq;
